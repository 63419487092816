import { App } from "vue";
import axios, { AxiosInstance } from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;
  private static axiosInstance: AxiosInstance; // Declare axios instance

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    // app.use(VueAxios, ApiService.axiosInstance);
    // ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    // ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL_REAL + "/v2";

    // Create a new Axios instance
    ApiService.axiosInstance = axios.create();
    ApiService.axiosInstance.defaults.baseURL = process.env.VUE_APP_API_URL_REAL + "/v2";

    // Use the static Axios instance with VueAxios
    app.use(VueAxios, axios); 
  }

  /**
   * @description set the default HTTP request headers
   */
  // public static setHeader(): void {
  //   ApiService.vueInstance.axios.defaults.headers.common[
  //     "Authorization"
  //   ] = `Token ${JwtService.getToken()}`;
  //   ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
  //     "application/json";
  // }

  public static getUrl(): string {
    return ApiService.axiosInstance.defaults.baseURL || ''
  }

  public static setHeader(): void {
    ApiService.axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `${JwtService.getToken()}`;
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.axiosInstance.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.axiosInstance.get(`${resource}/${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.axiosInstance.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.axiosInstance.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.axiosInstance.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.axiosInstance.delete(resource);
  }
}

export default ApiService;
