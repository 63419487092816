// ZeeJs.js
window.zeeJs = {};

// Array.prototype.findFirst = function(stringKey, stringProp){
//     let opt = this.find(e=>e[stringProp]==stringKey);
//     if(opt)return opt;
//     else return undefined;
// }

// Array.prototype.findFirstUsingEquality = Array.prototype.find;
// Array.prototype.findAllUsingEquality = Array.prototype.filter;

// Array.prototype.findAll = function(stringKey, stringProp){
//     let list = this.filter(e=>e[stringProp]==stringKey);
//     return list
// }

// Array.prototype.findAllUsingMultiProp = function(arrStringKey, arrStringProp){
//     let list = this.filter(e=>zeeJs.compareByMultiProp(e, arrStringKey, arrStringProp));
//     return list;
// }

// Array.prototype.findFirstUsingMultiProp = function(arrStringKey, arrStringProp){
//     let opt = this.find(e=>zeeJs.compareByMultiProp(e, arrStringKey, arrStringProp));
//     if(opt)return opt;
//     else return undefined;
// }

// Array.prototype.groupBy = function(arrStringProp){
//     var dataList = [];
//     var size = arrStringProp.length;

//     for(let obj of this){
//         let opt = dataList.find((p)=>{
//             let string = "";
//             let idx = 1;
//             for(let prop of arrStringProp){
//                 string+= " p['"+prop+"'] == obj['"+prop+"']";
//                 if(idx<size)string += " && ";
//                 idx++;
//             }
//             string+= " ;";
//             if(string!="")return eval(string);
//             else return false;
//         })

//         if(opt){
//             opt.items.push(obj);
//         }else{
//             let newObj = {items: []};
//             for(let prop of arrStringProp){
//                 newObj[prop] = obj[prop];
//             }
//             newObj['items'].push(obj);
//             dataList.push(newObj)
//         }
//     }
//     return dataList;
// }

// Array.prototype.orderBy = function(arrStringProp, sortType){
//     arrStringProp = arrStringProp.reverse(); //['order1', 'order2']
//     // ASC - DES
//     if(sortType) sortType = "ASC";
//     let p1 = 1;
//     let p2 = -1;
//     if(sortType=="ASC"){
//         p1=-1, p2=1;
//     }

//     this.sort((a, b)=>{
//         return zeeJs.comparatorSort(a, b, p1, p2, arrStringProp);
//     })
//     return this;
// }

Object.defineProperty(Array.prototype, "findFirst", {
  enumerable: false,
  value: function (stringKey, stringProp) {
    let opt = this.find((e) => e[stringProp] == stringKey);
    if (opt) return opt;
    else return undefined;
  },
});

Object.defineProperty(Array.prototype, "findFirstUsingEquality", {
  enumerable: false,
  value: Array.prototype.find,
});

Object.defineProperty(Array.prototype, "findAllUsingEquality", {
  enumerable: false,
  value: Array.prototype.filter,
});

Object.defineProperty(Array.prototype, "findAll", {
  enumerable: false,
  value: function (stringKey, stringProp) {
    let list = this.filter((e) => e[stringProp] == stringKey);
    return list;
  },
});

Object.defineProperty(Array.prototype, "findAllUsingMultiProp", {
  enumerable: false,
  value: function (arrStringKey, arrStringProp) {
    let list = this.filter((e) =>
      zeeJs.compareByMultiProp(e, arrStringKey, arrStringProp)
    );
    return list;
  },
});

Object.defineProperty(Array.prototype, "findFirstUsingMultiProp", {
  enumerable: false,
  value: function (arrStringKey, arrStringProp) {
    let opt = this.find((e) =>
      zeeJs.compareByMultiProp(e, arrStringKey, arrStringProp)
    );
    if (opt) return opt;
    else return undefined;
  },
});

Object.defineProperty(Array.prototype, "groupBy", {
  enumerable: false,
  value: function (arrStringProp) {
    var dataList = [];
    var size = arrStringProp.length;

    for (let obj of this) {
      let opt = dataList.find((p) => {
        let string = "";
        let idx = 1;
        for (let prop of arrStringProp) {
          string += " p['" + prop + "'] == obj['" + prop + "']";
          if (idx < size) string += " && ";
          idx++;
        }
        string += " ;";
        if (string != "") return eval(string);
        else return false;
      });

      if (opt) {
        opt.items.push(obj);
      } else {
        let newObj = { items: [] };
        for (let prop of arrStringProp) {
          newObj[prop] = obj[prop];
        }
        newObj["items"].push(obj);
        dataList.push(newObj);
      }
    }
    return dataList;
  },
});

Object.defineProperty(Array.prototype, "orderBy", {
  enumerable: false,
  value: function (arrStringProp, sortType) {
    arrStringProp = arrStringProp.reverse(); //['order1', 'order2']
    // ASC - DES
    if (sortType == null || sortType == "" || sortType == undefined)
      sortType = "ASC";

    let p1 = -1;
    let p2 = 1;
    if (sortType == "ASC") (p1 = 1), (p2 = -1);

    this.sort((a, b) => {
      return zeeJs.comparatorSort(a, b, p1, p2, arrStringProp);
    });
    return this;
  },
});

zeeJs.comparatorSort = function (objA, objB, p1, p2, arrStringProp) {
  var size = arrStringProp.length;
  let string = "";
  let number = 0;
  for (let prop of arrStringProp) {
    string += " if(objA['" + prop + "'] > objB['" + prop + "'])number = p1;";
    string += " if(objA['" + prop + "'] < objB['" + prop + "'])number = p2;";
  }
  eval(string);
  if (string != "") return number;
  else return 0;
};

zeeJs.compareByMultiProp = function (objData, arrStringKey, arrStringProp) {
  var size = arrStringProp.length;
  let string = "";
  let idx = 1;
  for (let prop of arrStringProp) {
    string +=
      " objData['" +
      prop +
      "'] == arrStringKey[{{__INDEX__NO__}}]".replace(
        "{{__INDEX__NO__}}",
        idx - 1
      );
    if (idx < size) string += " && ";
    idx++;
  }
  string += " ;";
  if (string != "") return eval(string);
  else return false;
};

// zeeJs.renameKey = function(object, key, newKey){
//   var clonedObj = clone(object);
//   var targetKey = clonedObj[key];
//   delete clonedObj[key];
//   clonedObj[newKey] = targetKey;
//   return clonedObj;
// };

zeeJs.allTime = [];
zeeJs.createTime = function (timeName, type) {
  zeeJs.allTime = [];
  return zeeJs.addTime(timeName, type);
};
zeeJs.addTime = function (timeName, type) {
  let intTime = 1000;
  if (type == "MILLIS") {
    zeeJs.allTime.push({
      timeName: timeName,
      timeNum: 0,
      desc: "in millis",
      type: type,
    });
    intTime = 1;
  } else if (type == "SECOND")
    zeeJs.allTime.push({
      timeName: timeName,
      timeNum: 0,
      desc: "in second",
      type: "SECOND",
    });
  else
    zeeJs.allTime.push({
      timeName: timeName,
      timeNum: 0,
      desc: "in second",
      type: "SECOND",
    });
  let curInterval = setInterval(function () {
    let opt = zeeJs.allTime.findFirst(timeName, "timeName");
    if (opt != undefined) {
      opt.timeNum += 1;
    }
  }, intTime);
  let opt = zeeJs.allTime.findFirst(timeName, "timeName");
  if (opt != undefined) opt.interval = curInterval;
  return {
    stopTime: function (timeName) {
      let opt = zeeJs.allTime.findFirst(timeName, "timeName");
      if (opt != undefined) {
        clearInterval(opt.interval);
        let lastTime = opt.timeNum;
        zeeJs.allTime.deleteFirst(timeName, "timeName");

        return lastTime;
      } else {
        return 0;
      }
    },
    getCurrentTime: function (timeName) {
      let opt = zeeJs.allTime.findFirst(timeName, "timeName");
      if (opt != undefined) return opt.timeNum;
      else return 0;
    },
  };
};
