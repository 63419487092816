import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";
import qs from "qs";

// const store = useAuthStore();
// const router = useRouter();

function getRes(response) {
  const store = useStore();
  const router = useRouter();
  const newRess = {
    status: response.status,
    data: response.data,
    error: "",
    message: response.statusText,
    message_opt: "",
    total: response.total,
  };

  if (response.status == 200) {
    if (response.data.status >= 2000 && response.data.status <= 2999) {
      Swal.fire({
        text: "Error On DB",
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    }

    if (response.data.status >= 3000 && response.data.status <= 3999) {
      if (response.data.status == 3001) {
        Swal.fire({
          text: "Sorry, session end please login again",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        store.dispatch(Actions.LOGOUT);
        // store.logout();
        router.push({ name: "sign-in" });
        // location.reload();
      } else if (response.data.status == 3002) {
        Swal.fire({
          text: "Sorry, Access Denied",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }else{
        Swal.fire({
          text: "Error On Server",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    }

    newRess.status = response.data.status || response.data.Status;
    newRess.data = response.data.data || response.data.Data;
    newRess.error = response.data.error;
    newRess.message = response.data.message || response.data.Message;
    newRess.message_opt = response.data.message_opt;
    newRess.total = response.data.total;

    if(newRess.data == null 
      || newRess.data == ""
      || newRess.data == undefined)
        newRess.data = []
  } else {
    //gak tau mau apa
  }

  return newRess;
}

function objToFormData(itemObj) {
  const form_data = new FormData();

  for (const key in itemObj) {
    let val = itemObj[key];
    if (val === null) val = "";

    if (
      typeof itemObj[key] === "object" &&
      itemObj[key] !== null &&
      itemObj[key].constructor !== File &&
      itemObj[key].constructor !== Blob
    ) {
      form_data.append(key, JSON.stringify(val));
    } else {
      form_data.append(key, val);
    }
  }

  return form_data;
}

export default class {
  static PostData(BaseApi, url, objData, onSuccess, onError) {
    const szData = objToFormData(objData);
    return BaseApi.post(url, szData)
      .then((data) => onSuccess(getRes(data)))
      .catch((err) => onError(err));
  }

  static PostDataXWWW(BaseApi, url, objData, onSuccess, onError) {
    return BaseApi.post(
      url,
      qs.stringify(objData),
      "application/x-www-form-urlencoded"
    )
      .then((data) => onSuccess(getRes(data)))
      .catch((err) => onError(err));
  }

  static GetData(BaseApi, url, objData, onSuccess, onError) {
    return BaseApi.get(url, objData)
      .then(response => onSuccess(getRes(response)))
      .catch(err => onError(err));
  }

  static DeleteData(BaseApi, url, onSuccess, onError) {
    return BaseApi.delete(url)
      .then(data => onSuccess(getRes(data)))
      .catch(err => onError(err));
  }

  static PutData(BaseApi, url, objData, onSuccess, onError) {
    const szData = objToFormData(objData);
    return BaseApi.put(url, szData)
    .then(response => {
      const data = getRes(response);
      onSuccess(data);
      return data;
    })
    .catch(err => {
      if (onError && typeof onError === 'function') {
        onError(err);
      } else {
        console.error('Error during API call:', err);
      }
      throw err;
    });
  }
}
