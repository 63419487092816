import { App } from "vue";
import axios, { AxiosInstance } from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService2 {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;
  private static axiosInstance: AxiosInstance; // Declare axios instance

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService2.vueInstance = app;
    // ApiService2.vueInstance.use(VueAxios, axios);
    // ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    // ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL_REAL + "/api";
    // ApiService2.vueInstance.axios.defaults.baseURL = "http://localhost:9000/api/"; 

    // Create a new Axios instance
    ApiService2.axiosInstance = axios.create();
    ApiService2.axiosInstance.defaults.baseURL =  process.env.VUE_APP_API_URL_REAL2 + "/api"; //"http://192.168.18.75:9000/api/"; 

    // Use the static Axios instance with VueAxios
    app.use(VueAxios, axios); 
  }

  /**
   * @description set the default HTTP request headers
   */
  // public static setHeader(): void {
  //   ApiService.vueInstance.axios.defaults.headers.common[
  //     "Authorization"
  //   ] = `Token ${JwtService.getToken()}`;
  //   ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
  //     "application/json";
  // }

  public static getUrl(): string {
    return ApiService2.axiosInstance.defaults.baseURL || ''
  }

  public static setHeader(): void {
    ApiService2.axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `${JwtService.getToken()}`;
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService2.axiosInstance.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService2.axiosInstance.get(`${resource}/${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService2.axiosInstance.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService2.axiosInstance.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService2.axiosInstance.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService2.axiosInstance.delete(resource);
  }
}

export default ApiService2;
