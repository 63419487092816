import ApiService from "@/core/services/ApiService";
import ApiService2 from "@/core/services/ApiService2";
import JwtService from "@/core/services/JwtService";
import Services from "@/core/services/ArrService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;

  UserEmail: string;
  UserID:  string;
  UserLevel: string
  UserName: string;
  UserPassword: string;
  UserToken: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    user['name'] = user.data.UserName
    user['surname'] = user.data.UserName
    user['email'] = user.data.UserEmail
    user['password'] = user.data.UserPassword
    user['api_token'] = user.data.UserToken
    this.user = user;
    this.errors = {};
    JwtService.saveToken(user.data.UserToken);

    localStorage.setLocalStorage('UserName', user.data.UserName);
    localStorage.setLocalStorage('UserToken', user.data.UserToken);
    localStorage.setLocalStorage('UserID', user.data.UserID);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  async [Actions.LOGIN](credentials) {
    // return ApiService.post("login", credentials)
    //   .then(({ data }) => {
    //     this.context.commit(Mutations.SET_AUTH, data);
    //   })
    //   .catch(({ response }) => {
    //     this.context.commit(Mutations.SET_ERROR, response.data.errors);
    //   });

    const mydata = {
      user_email: credentials.email,
      user_password: credentials.password
    };

    return await Services.PostDataXWWW(ApiService2, "login", mydata, response=>{
      this.context.commit(Mutations.SET_AUTH, response);
    }, err =>{
      this.context.commit(Mutations.SET_ERROR, err);
      // setError(err);
    })
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      // ApiService.setHeader(); //error kalo dikirim token
      ApiService2.setHeader();
      // ApiService.post("verify_token", payload)
      //   .then(({ data }) => {
      //     this.context.commit(Mutations.SET_AUTH, data);
      //   })
      //   .catch(({ response }) => {
      //     this.context.commit(Mutations.SET_ERROR, response.data.errors);
      //     this.context.commit(Mutations.PURGE_AUTH);
      //   });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
